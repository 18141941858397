.toggle-container {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
}

.toggle-label {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin-right: 10px;
}

.toggle-input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    transition: 0.4s;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
}

.toggle-input:checked + .toggle-slider {
    background-color: #007bff;
}

.toggle-input:checked + .toggle-slider:before {
    transform: translateX(14px);
}

.toggle-text {
    font-size: 14px;
    color: #333;
}

.dark-theme {
    .toggle-text{
        color: #EDEDED;
    }
}