.content-container {
  display: flex;
  height: 92vh;
  width: 100vw;
  background-image: url("../icon/img_4.png");
  background-size: cover;

  padding: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.page-title {
  font-size: 40px;
  font-weight: 700;
  color: #f7f7f7;
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
  opacity: 0;
  transform: translateY(50px) scale(0.8);
  animation: beautifulFadeInUp 1.5s ease-out forwards, pulse 2s ease-in-out 1.5s forwards;
}

@keyframes beautifulFadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px) scale(0.8);
  }
  60% {
    opacity: 1;
    transform: translateY(-10px) scale(1.05);
  }
  80% {
    transform: translateY(5px) scale(0.98);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}
