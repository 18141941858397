.user-profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin-left: 270px;
}

.form-toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  button {
    background-color: #ffffff; /* White background when inactive */
    border: 1px solid #ccc;
    border-radius: 0;
    padding: 10px 15px;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;

      &:hover {
        background-color: rgba(43, 199, 133, 0.15); /* Slightly lighter green on hover */
      }
    }

    &:last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;

      &:hover {
        background-color: rgba(164, 97, 216, 0.15); /* Slightly lighter purple on hover */
      }
    }

    &.active {
      color: #000; /* Use a dark color for the text in active state */

      &:hover {
        background-color: inherit; /* No color change on hover for active button */
      }
    }

    &.active:first-child {
      background-color: rgba(43, 199, 133, 0.3); /* Slightly darker green on hover */
      border-color: #f0f0f0;
    }

    &.active:last-child {
      background-color: rgba(164, 97, 216, 0.3); /* Slightly darker purple on hover */
      border-color: #A461D81A;
    }
  }
}

.user-profile-content {
  width: 100%;
  max-width: 500px;
  text-align: center;
}

.user-profile-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #24292e;
}

.form-group {
  margin-bottom: 5px;
  text-align: left; /* Ensure labels are aligned to the left */
  color: #111315;
}

.form-label {
  font-size: 16px;
  margin-bottom: 5px;
  color: #586069;
  display: block;
}

.form-input {
  width: 100%;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #d1d5da;
  background-color: #f8f9fa; /* Light grey background for a subtle effect */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05); /* Slight inner shadow for depth */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: #2bc785; /* Green border on focus */
    box-shadow: 0 0 8px rgba(43, 199, 133, 0.2); /* Green glow on focus */
    outline: none; /* Remove the default outline */
  }
}

.password-input-container {
  position: relative;
  width: 320px; /* Consistent width with other inputs */
  z-index: 1; /* Increase the z-index */
  overflow: hidden; /* Prevents the overlap effect */
}

.password-input-container input {
  padding: 12px 40px 12px 20px; /* Padding adjusted to account for the eye icon */
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #d1d5da;
  background-color: #f8f9fa;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: #2bc785;
    box-shadow: 0 0 8px rgba(43, 199, 133, 0.2);
    outline: none;
  }
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #6c757d; /* Soft grey color for the icon */
  transition: color 0.3s ease;

}

.form-button {
  width: 100%;
  padding: 10px 20px;
  background-color: #529293; /* Normal state background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 14px;
  margin-top: 5px;

  &:hover {
    background-color: #458C8D;
  }
}

.error-message {
  color: #d9534f;
  margin-top: 10px;
}

/* Mobile adjustments */
@media (max-width: 767px) {
  .user-profile-container {
    padding: 20px;
    margin-left: 0;
    box-shadow: none;
    border-radius: 0;
  }

  .user-profile-title {
    font-size: 24px;
  }

  .toggle-button, .form-input, .form-button {
    font-size: 14px;
    //padding: 8px 16px; /* Adjust padding for smaller screens */
  }

  .form-button {
    width: 100%; /* Ensure the button spans full width on mobile */
  }

  .form-group {
    margin-bottom: 15px;
  }

  .password-input-container input {
    padding-right: 90px; /* Adjust padding for smaller screens */
    width: 100%;
  }
}

.dark-theme {
  .user-profile-container {
    background: #111315;

    .form-group {
      color: #EDEDED;
    }
    .user-profile-title,
    .form-label, /* Ensure form-label is included here */
    .error-message {
      color: #EDEDED !important; /* Use !important to override any other styles */
    }

    .form-input, .password-input-container input {
      background-color: #222;
      color: #EDEDED;
      border: 1px solid #333;
    }
  }

  .form-toggle-buttons {
    button {
      background-color: #111315; /* Darker background for buttons in dark theme */
      color: #EDEDED; /* White text for buttons in dark theme */
      border: 1px solid #111315;

      &.active {
        color: #fff; /* White text for active buttons */
      }

      &.active:first-child {
        background-color: #2BC7851A; /* Background for the first button when active */
        border-color: #111315;
      }

      &.active:last-child {
        background-color: #A461D81A; /* Background for the second button when active */
        border-color: #A461D81A;
      }
    }
  }
}
