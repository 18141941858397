/*/* *telegram.css/!*/
.confirm-telegram-card, .connect-telegram-card {
    background-color: #fff;
    border-radius: 10px;
    width: 24rem;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.316);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

@media (max-width: 768px) {
    .confirm-telegram-card, .connect-telegram-card {
        width: 25rem;
    }
    body {
        background: url("/src/icon/img.png") no-repeat center center;
        background-size: cover;
    }
}

@media (max-width: 414px) {
    .confirm-telegram-card, .connect-telegram-card {
        width: 24rem;
    }
}

@media (max-width: 400px) {
    .confirm-telegram-card, .connect-telegram-card {
        width: 23rem;
    }
}

@media (max-width: 390px) {
    .confirm-telegram-card, .connect-telegram-card {
        width: 23rem;
    }
}

@media (max-width: 375px) {
    .confirm-telegram-card, .connect-telegram-card {
        width: 21rem;
    }
}

.confirm-telegram-card img.logo, .connect-telegram-card img.logo {
    width: 100px;
    margin-bottom: 1rem;
}

.confirm-telegram-card h2, .connect-telegram-card h2 {
    margin-bottom: 1rem;
}

h1 {
    font-size: 1.5rem;
    color: #333;
    margin: 20px 0;
}

/*.confirm-telegram-card p, .connect-telegram-card p {
    margin-bottom: 2rem;
    font-size: 16px;
    color: #333;
}*/

.confirm-telegram-card .phone-input, .connect-telegram-card .phone-input, .confirm-telegram-card .code-input {
    padding: 10px;
    margin: 5px 0;
    width: 100%;
    background: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 14px;
}

.confirm-telegram-card .button-group, .connect-telegram-card .button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.confirm-telegram-card .go-back-btn, .confirm-telegram-card .confirm-btn, .connect-telegram-card .submit-btn {
    width: 48%;
    height: 39px;
    margin-top: 20px;
    padding: 10px;
    background: #529293;
    cursor: pointer;
    border: none;
    color: #fff;
    font-weight: bold;
    border-radius: 8px;
    border: 1px solid transparent;
}

.send-btn {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    padding: 10px 20px;
    background: #529293;
    cursor: pointer;
    border: none;
    color: #ffffff;
    font-weight: bold;
    border-radius: 5px;
    transition: background 0.3s ease, margin-top 0.3s ease;
}

.send-btn.error-margin {
    margin-top: 30px;
}

.send-btn:hover {
    background: #458C8D;
}

.send-btn:active {
    background: #65ACAD;
}

.confirm-telegram-card .go-back-btn {
    color: black;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.header-container-white-telegram {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
.loading-indicator-telegram {
    width: 50px;
    height: auto;
    animation: spin 2s linear infinite;
    margin-bottom:10px;
}
