@import "./../styles/variables.scss";
//layout.module.scss
.layout {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - #{$headerHeight});
    //padding-top: $headerHeight;
    background: $bg;
}

.mainContent {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $spacing-md;
    background: url("../icon/img_2.png") no-repeat center center;
    background-size: cover;
    color: white; // Optional: to ensure the text is readable

    .chat {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $light;
        padding: $spacing-md;
        border-radius: $borderRadius;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        button {
            margin: $spacing-md 0;
        }
    }
}

.sidebar, .sidebar-right {
    width: 250px;
    background: $navbarBackground;
    padding: $spacing-md;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @include breakpoint(md) {
        width: 200px;
    }
}

.sidebar {
    margin-right: $spacing-md;
}

.sidebar-right {
    margin-left: $spacing-md;
}

@media (max-width: 768px) {
    .layout {
        flex-direction: column;
        align-items: center;
    }

    .sidebar, .sidebar-right {
        width: 100%;
        margin: 0;
    }

    .mainContent {
        width: 100%;
    }
}
