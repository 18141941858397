
.dark-theme .history-card .refresh-icon {
  content: url("icon/img_22.png"); /* Light theme refresh icon */
}

.history-card .refresh-icon {
  content: url("icon/img_13.png"); /* Dark theme refresh icon */
}

.dark-theme .history-card .delete-icon {
  content: url("icon/img_21.png"); /* Light theme delete icon */
}

.history-card .delete-icon {
  content: url("icon/img_14.png"); /* Dark theme delete icon */
}

 .filter-image {
   content: url("icon/img_9.png");
   display: flex;
   width: 20px;
   height: 20px;

}


.dark-theme .filter-image {
  content: url("icon/img_20.png");

}
.filter-input-section .add-btn-btn {
  content: url("icon/img_24.png");


}
.dark-theme .add-btn-btn {
  content: url("icon/img_23.png");

}





.filter-icon-container .filter-icon-mobile{
  content: url("icon/img_17.png");
}


.dark-theme .filter-icon-container .filter-icon-mobile{
  content: url("icon/img_25.png");
}
