$spacing-md: 16px;
$primary: #007bff;
$light: #f8f9fa;
$border-radius: 4px;
$dark: #343a40;
$text-light: #ffffff;
$background: #ffffff; // White background color
$secondary: #6c757d;
$blue: #007bff;
$red: #dc3545;
$active-bg: #50B5FF1A; // Light blue background for active tabs
$smooth-gray: #b0b0b0; /* Гарний напівсірий колір */
$light-gray: #e0e0e0; /* Світліший відтінок для фону */
$dark-gray: #707070; /* Темніший для тексту або рамок */


body.custom-background {
    background: $background !important;
    margin: 0;
    font-family: Arial, sans-serif;
}

.container {
    display: flex;
    max-height: 200vh;

}


.container-chat {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    @media only screen and (max-width: 768px) {
        .container-chat {
            padding-bottom: -120px; /* Ensure input field does not cover last message */
        }
    }



}



.scrape-forward-section.disabled input.filter-input {
    background-color: #b0b0b0 !important; /* Світло-сірий фон */
    color: $dark-gray !important; /* Темно-сірий текст */
    border: 1px solid $smooth-gray !important; /* Рамка гарного напівсірого */
}

//.scrape-forward-section.disabled {
//    pointer-events: none;
//    opacity: 0.6;
//    background-color: $smooth-gray ;
//    color: $dark-gray ;
//    border-radius: 8px;
//
//}
.scrape-forward-section.disabled {
    position: relative;
    cursor: not-allowed;
    background-color: $smooth-gray;
    color: $dark-gray;
    border-radius: 8px;
}

.scrape-forward-section.disabled input,
.scrape-forward-section.disabled textarea {
    pointer-events: none; /* Забороняє взаємодію з інпутом */
    background-color: $dark-gray; /* Можна змінити колір для забороненого поля */
}


.scrape-forward-section.disabled:hover::after {
    content: "Click Scrape & Forward Mode to activate";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 1); /* Непрозорий чорний фон */
    color: #FFF; /* Білий текст */
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10; /* Зробіть його вищим, щоб уникнути накладення */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Легка тінь для об'єму */
}




.scrape-forward-section {
    border: 2px solid #ffffff;
    border-radius: 8px;
    padding: 1px;
    margin: 1px;
}

.scrape-forward-label {
    flex-shrink: 0;
    font-size: 14px;
    margin-left: 14px;
}

.dark-theme {
    .scrape-forward-label {
        color: #EDEDED; // Default color for light theme
        &.disabled {
            color: #000000; // Disabled state color
        }
    }

    .scrape-forward-label {
        color: #EDEDED;
    }

    .scrape-forward-section.disabled {
        border: none;
        background-color: #b0b0b0;
    }

    .scrape-forward-section {
        border: none;
    }
}





.fade-out {
    opacity: 1;
    transition: opacity 0.5s ease;
}

.fade-out.hidden {
    opacity: 0;
}

.header-chat {
    background: #FFFFFF;
    display: flex;
    align-items: center;
    border-width: 1px 1px 1px 1px; /* top right bottom left */
    //border-style: solid;
    border-color: #ddd;

    @media(max-width: 768px) {
        &.header-chat .tabs {
            padding: 0;
        }
    }

    .tabs {
        display: flex;
        height: 58px;
        flex-grow: 1;

        .tab {
            font-size: 14px;
            width: 211px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #FFFFFF;
            transition: background 0.3s;
            border: 1px solid #0000001A;
            align-self: center;


            @media (max-width: 768px) {
                width: 133px;
                height: 42px;
            }

                &:first-child {
                border-radius: 8px 0 0 8px; /* Left corners rounded */
                margin-left: 9px;
            }

            &:last-child {
                border-radius: 0 8px 8px 0; /* Right corners rounded */
            }

            &.active {
                background: #50B5FF1A; /* Light blue background for active tab */
            }

            &.deleted.active {
                background: #FF424D1A; /* Light red background for active "deleted" tab */
            }

            &:not(.active) {
                color: #000000; /* Inactive tab text color */
            }
        }

        .dot {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 8px;

            &.blue {
                background: #50B5FF;
            }

            &.red {
                background: #FF424D;
            }
        }

        span {
            display: inline-block;
        }
    }

    .filter-icon-container {
        display: flex;
        align-items: center;
        justify-content: right;

        .filter-icon-mobile {
            width: 40px;
            height: 40px;
            cursor: pointer;
            margin-right: 7px;
        }
    }

    border-bottom: 1px solid #0000001A;
}


.dark-theme {
    .header-chat {
        background: #111315;
        border-bottom: 1px solid #EDEDED1A
    }

    .tabs {
        .tab {
            &.active {
                &.all {
                    color: #EDEDED;

                    background: #50B5FF1A;
                }
                &.deleted {
                    color: #EDEDED;
                    background: #FF424D1A;
                }
            }

            &:not(.active) {
                background: #111315;
                color: #EDEDED;
            }
        }

    }
}

.filter-icon {
    position: fixed; // Fixes the icon in place relative to the viewport
    bottom: 20px; // Space from the bottom of the viewport
    right: 20px; // Space from the right of the viewport
    background: #fff; // Background color for visibility
    border-radius: 50%; // Optional: makes the icon circular
    padding: 10px; // Adds space around the icon
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Adds a subtle shadow
    cursor: pointer; // Shows pointer cursor on hover
    z-index: 1000; // Ensures the icon stays on top of other elements

    @media (max-width: 768px) {
        display: block; // Show on mobile devices

    }
}

.filter-icon:hover {
    background: #f0f0f0; // Optional: changes background color on hover
}
.notification-btn {
    position: relative;
    color: white;
    width: 119px;
    height: 35px;
    border-radius: 4px;
    border: none;
    background: #529293;
    margin-right: 9px;
    transition: color 0.3s ease;

    &.saved-btn {
        background: #529293;
    }

    &:hover {
        background: #458C8D;
    }

    &:active {
        background: #65ACAD;
        transform: translateY(2px);
    }

    &.applied {
        background: #529293;
        color: #ccc;
    }

    &.dark-theme {
        color: white;

        &.saved-btn {
            color: white; /* White text for saved button in dark theme */
        }
        &.saved-btn:hover {
            color: white; /* White text for saved button in dark theme */
        }
    }
}

.dark-theme {
    .filter-label, .notification-btn, .reset-btn, .channel-messages-option {
        color: #EDEDED;
    }

    .filter-container {
    }

    .notification-btn {
        color: #EDEDED;
    }

    .notification-btn:hover {
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
    }

    .notification-btn.saved-btn {
        color: white; /* Ensures the saved button has white text in dark theme */
    }

    .notification-btn.saved-btn:hover {
        color: #EDEDED; /* Changes text color on hover when the button is in the saved state */
        border: 1px solid #EDEDED; /* Ensure border matches hover color */
    }
}




.filter-container {
    background: #ffff;
    //height: 100vh;
    width: 400px;
    border-right: 1px solid #ccc;
    transition: transform 0.3s ease;

    &.filter-open {
        max-height: calc(100vh - 50px); // Dynamically adjusts with the viewport
        overflow-y: auto;
    }

    &.filter-closed {
        max-height: 400px; // Fixed height when closed
        overflow-y: auto;
    }

    &.collapsed {
        transform: translateX(-100%);
    }

    @media (max-width: 768px) {
        &.filter-container {
            width: 100%; /* Changed to 100% to make it full-width on smaller screens */
            height: 100vh;
        }
    }

    .filter-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;

        .filter-label {
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
            flex-grow: 1;
            text-align: center;
            /* Removed width, height, top, and left properties as they are not necessary */
        }



        .toggle-btn {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 1.5em;
            padding: 0;
            margin: 0;
        }
    }



    //.filter-options {
    //    //margin-top: $spacing-md;
    //    label {
    //        display: block;
    //        margin-bottom: $spacing-md;
    //
    //    }


        .apply-btn, .notification-btn, .reset-btn {
            position: relative;
            color: white;
            width: 119px;
            height: 35px;
            border-radius: 4px;
            border: none;
            background: #529293;
            margin-right: 9px; /* Відстань між кнопками */


            &:disabled {
                background: #ccc; /* Gray background for disabled state */
                color: #666; /* Gray text for disabled button */
                cursor: not-allowed; /* Non-clickable cursor */
                opacity: 0.7; /* Slightly faded appearance */
                &:hover {
                    background: #ccc; /* Ensure hover doesn't change the background */
                }
            }
            @media (max-width: 768px) {
                width: 140px;
            }

            @media (max-width: 820px) {
                width: 220px;
            }


            @media (max-width: 540px) {
                width: 160px;
            }


            @media (max-width: 441px) {
                width: 118px;
            }

            @media (max-width: 430px) {
                width: 124px;
            }

            @media (max-width: 412px) {
                width: 118px;
            }

            @media (max-width: 390px) {
                width: 116px;
            }

            @media (max-width: 375px) {
                width: 110px;
            }

            @media (max-width: 360px) {
                width: 109px;
                margin-right: 6px;

            }

            @media (max-width: 344px) {
                width: 109px;
                margin-right: 3px;

            }
                   }

    .apply-btn:hover {
        background: #458C8D;
    }

    .apply-btn:active {
        background: #65ACAD;
        transform: translateY(2px);
        color: #EDEDED;
    }

    .apply-btn.applied {
        background: #529293;
        color: #EDEDED;
    }
    &.applied::before {
        content: '✔'; /* Checkmark */
        margin-right: 8px;
        font-size: 14px;
    }


        .notification-btn, .reset-btn {
            margin-left: 12px;
            background: none;
            color: black;
            border: 1px solid #52929380;

            &.saved-btn {
                top: 0; /* Set top to 0 when the button is in the saved state */
            }

        }
        .reset-btn{
            top:0;
            margin-left: auto;
        }




    .notification-btn:hover, .reset-btn:hover {
        background: none;
        color: #000000B2;
        border: 1px solid #52929380;
    }



        /* Щоб не було відстані після останньої кнопки, можна використати псевдо-клас :last-child */
        .apply-btn:last-child {
            margin-right: 0;
        }
    }

.tooltip-container {
    position: relative;
    display: inline-block;

    .question-mark-circle {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        color: #555; // Сірий текст
        font-size: 11px;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
        line-height: 20px;
        border: 1px solid #aaa; // Тонкий обідок
        margin-left: 1px;
    }

    .tooltip-text {
        visibility: hidden;
        width: 265px;
        background-color: #000;
        color: #fff;
        text-align: left;
        border-radius: 5px;
        padding: 10px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -50px;
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 14px;
        line-height: 1.5;

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #000 transparent transparent transparent;
        }

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    &:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }
}


.dark-theme {
    .filter-label, .notification-btn, .reset-btn, .channel-messages-option {
        color: #EDEDED;
    }
    .notification-btn:hover, .reset-btn:hover{
        color: #bbbbbb;
        border: 1px solid #52929380;

    }


    .filter-container {
        background: #111315;
        border-right: 1px solid #EDEDED1A;
    }

    .notification-btn.saved-btn {
        color: white; /* White text for saved button in dark theme */
    }

    .notification-btn.saved-btn:hover {
        border: 1px solid #529293;
        color: white; /* Ensure hover color stays white in dark theme */
    }

    .reset-btn:hover {
        background: none;
        color: #EDEDED;
        border: 1px solid #529293;
    }



}


/* Styling for the custom checkbox */
.custom-checkbox {
    border:none;
    width: 20px;
    height: 20px;
    margin-right: 8px; /* space between checkbox and text */
    vertical-align: middle; /* aligns checkbox with text */
    margin-left: 12px;

}

/* Styling for the text and results */
.channel-messages-option {

    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.9px;
    text-align: left;
    color: #111315;
}

.results-text {
    margin-left: 85px; /* space between "Show Channel Messages" and "Results:" */
    color: #777; /* light gray color for the results text */
}



.usernames-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    left: 9px;
    position: relative;
}

.username-tag {
    background-color: #e5f2f2;
    border: 1px solid #d1e1e1;
    border-radius: 4px;
    padding: 8px 12px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    line-height: 1px;
    color: #009688;
    gap: 10px;
    opacity: 1; /* Adjust as needed */
}

.dark-theme{
    .username-tag{
        background-color: #52929333;
        color: #529293;
        border: 1px solid #52929333 ;

    ;

    }
}

.username-tag:hover{
    background-color:  #5292934D;
;
}
.username-tag .remove-btn {
    display: flex;

    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    gap: 0;
    background: none;
    border: none;
    color: #009688;
    cursor: pointer;
    opacity: 1; /* Adjust as needed */
}


.username-input {
    position: relative;
}


.filter-input-section {
    width: 100%;
    margin-bottom: 1px; /* Додаємо відступ між секціями */
}

.input-with-button {
    display: flex;
    align-items: center;
    width: 100%;
}

.filter-input {
    width: 100%; /* Забезпечуємо повну ширину інпуту */
    padding-right: 40px; /* Додаємо відступ для кнопки всередині інпуту */
    box-sizing: border-box; /* Враховуємо padding та border у розмірах елемента */
}

.add-btn {

    background: none;
    border: none;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
    padding: 5px; /* Додаємо padding для полегшення кліку */
    margin-left: -40px; /* Відсуваємо кнопку всередину інпуту */
}
.add-btn-btn {


    padding: 5px; /* Додаємо padding для полегшення кліку */
    width: 26px;
    height: 26px;
    display: flex;
}
@media (max-width: 768px) {
    .add-btn-btn {
        display: flex;
    }

    .filter-input {
        padding-right: 35px; /* Регулювання padding для мобільних пристроїв */
    }
}



.notify {
    border-bottom: 1px solid #ccc;
    margin-top: 15px;
}

.notify-visible {
    margin-bottom: 15px;
}

.notify-hidden {
    margin-bottom: 10px;
}


.wrapper-chat {
    display: flex;
    flex: 1;
    overflow: hidden;



    @media (max-width: 768px) {
        .wrapper-chat {
            display: flex;
            flex-direction: column; /* Stack items vertically */
            justify-content: flex-end; /* Push HeaderChat to the bottom */
            height: 100%;
            overflow-x: hidden; /* Disable horizontal scrolling */
        }

        .header-chat {
            position: fixed; /* Keep HeaderChat fixed at the bottom */
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 10; /* Ensure HeaderChat is above other elements */
        }


    }}




.sidebar-right {
    width: 280px;
    background: #ffffff;
    padding: $spacing-md;
    border-left: 1px solid #ccc;

    .private-chats, .groups, .channels {
        margin-bottom: $spacing-md;
        color: #00000080;

        h4 {
            margin-bottom: $spacing-md;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .private-chat, .group {
            padding: $spacing-md;
            background: $secondary;
            border-radius: $border-radius;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            margin-bottom: $spacing-md;
            position: relative;
        }

        .right-photo {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }

        .group-link {
            color: #000000;
            width: 101px;
            height: 19px;
            position: relative;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-decoration: none;
            padding-bottom: 4px;
            display: block;
            left: 40px;
        }

        .private-link, .channel-link {
            color: #000000;
            width: 85px;
            height: 25px;
            margin-left: 40px;
            text-decoration: none;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flow;
            margin-bottom: 2px;
            font-size: 14px;
        }

        .view-link {
            font-size: 12px;
            font-weight: 400;
            line-height: 16.2px;
            color: #49A8FF;
            text-align: right;
            display: flow;
            margin-left: 10px;
            position: relative;
            transform: translateY(-17px);
            right: 15px;
            cursor: pointer;

        }

        .chevron-icon {
            margin-left: auto;
        }
        .scrollable-list {
            max-height: 230px;
            overflow-y: hidden; /* Initially hide the scrollbar */
        }

        .scrollable-list:hover,
        .scrollable-list.scrolling {
            overflow-y: auto; /* Show scrollbar on hover or during scroll */
        }

        .scrollable-list::-webkit-scrollbar {
            width: 10px;
            background-color: #f0f0f0;
            border-radius: 10px;
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
        }

        .scrollable-list::-webkit-scrollbar-thumb {
            background-color: #529293;
            border-radius: 10px;
            transition: background-color 0.2s ease-in-out;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        }

    }
}
.dark-theme .sidebar-right {
    background: #111315;
    color: #EDEDED; // or any other light text color
    border: 1px solid #EDEDED1A;

    .private-chats, .groups, .channels, .group-link, .private-link, .channel-link {
        color:  #EDEDED;

    }
}


.websocket-demo {
    padding: $spacing-md;
    width: 780px;
    button {
        margin-right: $spacing-md;
        padding: $spacing-md / 2;
        background: $primary;
        color: $text-light;
        border: none;
        border-radius: $border-radius;
        cursor: pointer;
        &:hover {
            background: lighten($primary, 10%);
        }
        &:disabled {
            background: darken($primary, 10%);
            cursor: not-allowed;
        }
    }
}
.history-section {
    display: flex;
    flex-direction: column;
    gap: $spacing-md;
    padding: $spacing-md;
    overflow-y: auto;
    border-radius: $border-radius;
    transition: max-height 0.3s ease, padding 0.3s ease;


    &.hidden {
        display: none;
    }
    // Default desktop view when filter is closed
    max-height: 800px;

    // Tablet view (adjust the breakpoint as needed)
    @media screen and (max-width: 1024px) {
        max-height: 470px;
    }

    // Mobile view when filter is closed
    @media screen and (max-width: 768px) {
        max-height: 700px;
    }

    &.filter-open {
        // Desktop view when filter is open

        // Mobile view when filter is open
        @media screen and (max-width: 768px) {
            max-height: 320px;
        }
    }

    &:hover {
        padding: $spacing-md;
    }


    .history-card {
        background: $light;
        border-radius: $border-radius;
        padding: $spacing-md;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        margin-bottom: $spacing-md;
        overflow-wrap: break-word;
        transition: background 0.3s ease, border 0.3s ease;




        &:hover {
            border: 1px solid #0000001A;
            background: #5292930D;
        }

        .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            margin-bottom: $spacing-md / 2;

            .user-icon {
                font-weight: bold;
                margin-right: 30px;
            }

            .username-icon {
                color: #529293;
                font-size: 14px;
                line-height: 18.9px;
                text-align: left;
                font-weight: normal;
            }

            .refresh-icon,
            .delete-icon {
                cursor: pointer;
                height: 20px;
                width: 20px;
                position: absolute;
                bottom: 10px;
                transition: bottom 0.3s ease;
            }

            .refresh-icon {
                right: 27px;
            }

            .delete-icon {
                right: 2px;
            }

            &:has(.user-icon) {
                .refresh-icon,
                .delete-icon {
                    bottom: 10px;
                }
            }

            &:not(:has(.user-icon)) {
                .refresh-icon,
                .delete-icon {
                    top: 10px;
                    bottom: unset;
                }
            }
        }

        p {
            margin: 0;
            width:250px;
        }
    }
}




/* Base styles */
.active-filters-section {
    margin-top: 10px;
    padding: 10px;
    border-radius: 12px;
}

.active-filters-header {
    display: block;
    width: 100%;
    background-color: #cfe9ed; /* Lighter blue for a cleaner look */
    border-radius: 12px;
    text-align: center;
    padding: 12px;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid #b0bec5;
    color: #006064; /* Darker teal text for contrast */
    transition: background-color 0.3s ease-in-out; /* Smooth transition on hover */

    &:hover {
        background-color: #b0e3ea; /* Slightly darker shade on hover */
    }
}

.active-filters-content {
    background-color: #ffffff; /* White background for better contrast */
    padding: 20px;
    margin-top: 15px;
    border-radius: 12px;
    border: 1px solid #cfd8dc;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08); /* Subtle shadow for depth */
}

.filter-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2px; /* Increased spacing for clarity */
    padding: 8px 0;
    border-bottom: 1px solid #e0e0e0; /* Separator between items */
}

.filter-item span:first-child {
    font-weight: 600; /* Slightly bolder for emphasis */
    margin-right: 12px;
    color: #004d40; /* Darker shade for labels */
}

.filter-value {
    color: #00796b; /* Teal color for the filter value */
    font-style: italic;
    font-size: 15px; /* Slightly increased font size */
}

/* Mobile-specific adjustments for reduced height */
@media (max-width: 600px) {
    .active-filters-section {
        margin-top: 2px; /* Minimized margin */
        padding: 3px; /* Minimized padding for reduced height */
    }

    .active-filters-header {
        font-size: 12px; /* Smaller font for mobile */
        padding: 4px; /* Further reduced padding */
    }

    .active-filters-content {
        padding: 8px; /* Further reduced padding */
        margin-top: 5px; /* Smaller margin for compact layout */
    }

    .filter-item {
        padding: 2px 0; /* Minimized padding between items */
        margin-bottom: 0; /* No margin between items */
    }

    .filter-item span:first-child {
        font-size: 12px; /* Smaller label font */
        margin-right: 4px; /* Reduced margin between label and value */
    }

    .filter-value {
        font-size: 13px; /* Smaller font size for filter values */
    }
}



.dark-theme .history-card {
    background: #111315;
    border: 1px solid #EDEDED1A;
    color:  #EDEDED;

}
.dark-theme .notify {
    border: 1px solid #EDEDED1A;

}
/* Custom Scrollbar Styling */
.history-section::-webkit-scrollbar {
    width: 10px;
}

.history-section::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.history-section::-webkit-scrollbar-thumb {
    background-color: #458C8D;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.history-section::-webkit-scrollbar-thumb:hover {
    background-color: #65ACAD;
}



.tab-btn, .tabNotification {
    background: white;
    position: relative;
    width: 181px;
    height: 42px;
    left: 12px;
    gap: 0;
    border-radius: 8px 0 0 8px;
    cursor: pointer;
    align-items: center;
    padding-left: 16px; /* Add some padding to make room for the dot */
    font-size: 14px;
    border: 1px solid #0000001A;

    @media (max-width: 360px) {
        width: 171px;

    }
}
.tabNotification{
    border-radius: 0 8px 8px 0;

}
.notifications-active {
    background: #A461D81A;
}

.history-active {
    background: #2BC7851A;
}

.dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px; /* Adjust as needed */
}

.purple {
    background: #A461D8; /* Adjust to the desired color */
}

.green {
    background: #2BC785; /* Adjust to the desired color */
}
.tab-btn:hover {
    background: #A461D81A;
    transition: background 0.2s ease-in-out; /* плавний перехід */
}

.tabNotification:hover {
    background: #2BC78508;
    transition: background 0.2s ease-in-out; /* плавний перехід */
}
.dark-theme {
    .tab-btn, .tabNotification {
        background: #111315; /* Dark background color */
        border: 1px solid #EDEDED1A;
        color: #EDEDED;}

    .notifications-active {
        background: #A461D81A;
    }

    .history-active {
        background: #2BC7851A; /* Keep the same background color */
    }
}
.scroll-button {
    position: fixed;
    bottom: 20px; /* Adjusted to make sure it's above the HeaderChat */
    right: 310px; /* Adjust this value to ensure it's within the boundaries */
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../icon/img_19.png");
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1000; /* Ensure it stays on top but below important elements */

    @media(max-width: 768px) {
        bottom: 80px; /* Adjust for mobile to keep above the filter and header */
        right: 40px;
    }
}
.no-scroll {
    overflow: hidden;
    height: 100%;
}

html.no-scroll,
body.no-scroll {
    overflow: hidden;
    height: 100%;
}

.dark-theme .scroll-button {
    background-image: url("../icon/img_18.png");
}

.unread-count {
    position: absolute;
    top: -10px;
    right: 10px; /* Додано для точного розташування */
    background-color: #00796B;
    color: #fff;
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 12px;
    min-width: 20px;
    text-align: center;
    z-index: 1001; /* Вищий z-index для перекриття зображення */
}

.arrow-icon img {
    width: 100%;
    height: auto;
}
.hiddenButton {
    display: none;
}
.dark-theme .no-history-message{
    color:#EDEDED;
}




.filter-input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.required-asterisk {
    color: red;
    margin-left: 5px;
    position: absolute;
    right: 265px;
    top: 50%;
    transform: translateY(-50%);


    @media(max-width: 768px) {
        right: 630px;
    }

    @media (max-width: 430px) {
        right: 292px;

    }

    @media (max-width: 414px) {
        right: 281px;

    }
    @media (max-width: 400px) {
        right: 257px;

    }

    @media (max-width: 375px) {
        right: 242px;

    }

    @media (max-width: 360px) {
        right: 227px;

    }

    @media (max-width: 344px) {
        right: 207px;

    }

}

.date-picker-container {
    position: absolute;
    flex-direction: column; /* Stack the date pickers vertically */
    gap: 16px; /* Maintain the gap between the date pickers */
    margin-left: 11px; /* Adjust the margin as needed */
    width: 100%; /* Use full width of the container */
    max-width: 376px; /* Ensure the container doesn't exceed 376px */
    overflow: visible; /* Ensure the date pickers aren't clipped */
    z-index: 1; /* Adjust z-index if necessary */

    @media(max-width: 768px) {
       max-width: 300px;
    }

}
//.MuiPickersPopper-root {
//    position: fixed !important;
//    top: 50% !important;
//    left: 50% !important;
//    transform: translate(-50%, -50%) !important;
//    z-index: 9999 !important;
//    max-height: 90vh; /* Ensure it stays within the viewport */
//    max-width: 90vw;
//    overflow-y: auto; /* Allow scrolling within the pop-up if needed */
//}


.dark-theme .MuiInputBase-input {
    color: #EDEDED;
}

.dark-theme .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 1px solid #EDEDED1A;
}



.dark-theme .MuiSvgIcon-root {
    color: #EDEDED;
}


.dark-theme .MuiInputLabel-root {
    color: #EDEDED !important;
}

.existing-email-error {
    color: #ff6666;
}

.get-deleted-btn-filter {
    background-color: #529293; /* Normal state background */
    color: #ffffff; /* White text */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    transition: background-color 0.3s ease, transform 0.1s ease, opacity 0.3s ease;
    display: flex;
    overflow: hidden;
    margin-right: 5px;
    width: 376px; /* Ensure the container doesn't exceed 376px */


    @media(max-width: 375px) {
        width: 356px; /* Ensure the container doesn't exceed 376px */
    }

    @media(max-width: 360px) {
        width: 340px; /* Ensure the container doesn't exceed 376px */
    }
    @media(max-width: 344px) {
        width: 310px;
    }
}

.get-deleted-btn-filter:hover {
    background-color: #458C8D; /* Hover state background */
}

.get-deleted-btn-filter:active {
    background-color: #65ACAD; /* Pressed state background */
    transform: scale(0.98); /* Slightly reduce size on press */
}

.get-deleted-btn-filter .text-wrapper {
    transition: opacity 0.3s ease;
}

.get-deleted-btn-filter.confirmed .text-wrapper {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.get-deleted-btn-filter.confirmed:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    color: #ffffff;
    transition: opacity 0.3s ease;
}

.unread-messages-marker {
    margin: 10px 0; // Adds space above and below the marker
    padding: 8px 12px; // Adds padding for better readability
    background-color: #1a1a2e; // Dark background to blend with the dark theme
    color: #ffffff; // White text for visibility in the dark theme
    text-align: center; // Center aligns the text
    font-weight: bold; // Makes the text bold
    border-radius: 5px; // Rounds the corners
    font-size: 14px; // Sets a readable font size

    // Add border to blend with the dark theme style
    border: 1px solid #EDEDED1A;
}


//.css-1roo1oa-MuiList-root-MuiMultiSectionDigitalClockSection-root:hover{
//    overflow-y: hidden;
//}
//.css-1roo1oa-MuiList-root-MuiMultiSectionDigitalClockSection-root {
//    width: 100%;
//    overflow-y: auto;
//    position: relative;
//}
//
//.css-1roo1oa-MuiList-root-MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
//    width: 10px;
//    background-color: #f0f0f0;
//    border-radius: 10px;
//    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
//}
//
///* Style the scrollbar thumb */
//.css-1roo1oa-MuiList-root-MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar-thumb {
//    background-color: #529293;
//    border-radius: 10px;
//    transition: background-color 0.2s ease-in-out;
//    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
//}
//
///* Ensure the hover effect only modifies the background color slightly */
//.css-1roo1oa-MuiList-root-MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar-thumb:hover {
//    background-color: #467c7d; /* Slightly darker shade */
//}



//button {
//    border: none;
//    padding: $spacing-md / 2;
//    margin: $spacing-md / 2 0;
//    cursor: pointer;
//    &.active {
//        background: $blue;
//        color: $text-light;
//    }
//    &.inactive {
//        background: $light;
//        color: $dark;
//        &:hover {
//            background: lighten($light, 10%);
//        }
//    }
//}
