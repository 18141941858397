.account-management-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px; /* Збільшено padding */
  background: #ffffff;
  border-radius: 16px; /* Збільшено border-radius */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  max-width: 900px; /* Restrict width for better focus */
  margin-left: 270px;
}

.account-content {
  text-align: center;
  width: 100%;
}

.account-title {
  font-size: 28px; /* Збільшено розмір шрифту */
  font-weight: bold;
  margin-bottom: 20px; /* Збільшено відступ знизу */
  color: #24292e;
}

.account-table {
  width: 100%;
  border-collapse: collapse;
  color: #333333;
  background-color: #f8f8f8;
  border-radius: 16px; /* Збільшено border-radius */
  overflow: hidden;
  font-size: 16px; /* Збільшено розмір шрифту */
}

.account-table th, .account-table td {
  padding: 12px 6px; /* Збільшено padding */
  text-align: left;
}

.account-table th {
  background-color: #e5e5e5;
  color: #333333;
  font-weight: 600;
  text-transform: uppercase;
}

.account-table td {
  background-color: #ffffff;
}

.account-table th:first-child,
.account-table td:first-child {
  width: 30px;
  text-align: center;
}

.account-table th:last-child,
.account-table td:last-child {
  text-align: right;
}

.status-active {
  display: flex;
  align-items: center;
  color: #4caf50;
  font-weight: bold;
}

.status-icon-active {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  background-color: #4caf50;
  border-radius: 50%; /* Зелений круг */
}

.status-inactive {
  display: flex;
  align-items: center;
  color: #e74c3c; /* Червоний колір тексту */
  font-weight: bold;
}

.status-icon-inactive {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  background-color: #e74c3c; /* Червоний колір квадрата */
  border-radius: 2px; /* Квадратна форма */
}

.stop-button {
  padding: 10px 20px; /* Збільшено padding */
  background-color: #e74c3c;
  color: #ffffff;
  border: none;
  border-radius: 8px; /* Збільшено border-radius */
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 14px; /* Збільшено розмір шрифту */
}

.stop-button:hover {
  background-color: #c0392b;
  box-shadow: 0 4px 12px rgba(192, 57, 43, 0.3);
}

.start-button {
  padding: 10px 20px;
  background-color: #2ecc71;
  color: #ffffff;
  border: none;
  border-radius: 8px; /* Збільшено border-radius */
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 14px; /* Збільшено розмір шрифту */
}

.start-button:hover {
  background-color: #27ae60;
  box-shadow: 0 4px 12px rgba(39, 174, 96, 0.3);
}

@media (max-width: 768px) {
  .account-management-container {
    margin-left: 260px;

  }
}

@media (max-width: 767px) {
  .account-management-container {
    margin-left: -20px;

  }
}

/* Mobile adjustments */
@media (max-width: 490px) {
  .account-table {
    font-size: 13px;
  }
  .stop-button{
    font-size:10px;
  }
}

@media (max-width: 415px) {
  .account-table {
    font-size: 12px;
  }
}

  @media (max-width: 400px) {
    .account-table {
      font-size: 9px;
    }
  }
  //.account-table th, .account-table td {
  //  padding: 2px 2px;
  //}


/* Dark theme */
.dark-theme {

  .account-management-container {
    background: #111315;

    .account-title {
      color: #EDEDED;
    }

    .account-table th, .account-table td {
      background-color: #2c2c2c;
      color: #EDEDED;
    }

    .status-active {
      color: #4caf50;
    }

    .stop-button {
      background-color: #d9534f;
    }

    .stop-button:hover {
      background-color: #c9302c;
    }
  }
}
