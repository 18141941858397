/* Existing styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
    background: url("/src/icon/img_4.png") no-repeat center center;
    background-size: cover;
    font-family: 'Poppins', sans-serif;

}


body.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.card-register, .card-login {
  background-color: #fff;
  border-radius: 20px;
  width: 24rem;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.316);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto;
  /*height: 400px;*/
}

.card-login {
  height: 360px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  background-color: #FAFAFB;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
}
h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 7px;
}
/* Add this to your filter.css or within a <style> tag in your Filter component */

.filter-container input.filter-input {
  background-color: #ffffff;
  position: static;
  width: 376px;
  height: 48px;
  margin-left: 12px;
  padding: 16px;
  border-radius: 8px;
  transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out; /* Додавання плавної анімації для border */






  @media (max-width: 768px) {
    width: 356px;
  }

  @media (max-width: 820px) {
    width: 675px;
  }


  @media (max-width: 540px) {
    width: 500px;
  }


 @media (max-width: 430px) {
    width: 398px;
  }

  @media (max-width: 414px) {
    width: 387px;
  }

  @media (max-width: 412px) {
    width: 367px;
  }

  @media (max-width: 375px) {
    width: 356px;
  }

  @media (max-width: 360px) {
    width:  340px;
  }

  @media (max-width: 344px) {
    width:  310px;
  }
  }
.filter-input::placeholder {
  color: #00000080;
  opacity: 1;
}


.dark-theme .filter-container input.filter-input {
  background-color: #111315;
  color: #EDEDED;
  border: 1px solid #EDEDED1A;

}
.dark-theme {
  .filter-input::placeholder {
    color: #24292e;
  }
}



.filter-container input.filter-input:hover {
  border: 1px solid #529293;
}

.filter-container input.filter-input:focus {
  border: 1px solid #529293;
  background-color: #FFFFFF;
  outline: none; /* Видалити стандартний outline браузера */
}

.dark-theme .filter-container input.filter-input:focus{
  background-color: #111315;
  color: #EDEDED;

}

.password-wrapper {
  position: relative;
  width: 100%;
  height: 50px; /* Ensure a consistent height */
}

.toggle-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.login-link {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
  color: #0062FF;
}

@media (max-width: 768px) {
  .login-link {
    font-size: 14px;
  }
  body {
    background: url("/src/icon/img.png") no-repeat center center;
    background-size: cover;
  }
}

@media (max-width: 400px) {
  .card-register, .card-login {
    width: 360px;
    /*height: 400px;*/
  }
}

@media (max-width: 375px) {
  .card-register, .card-login {
    width: 340px;
    height: 360px;
  }
}@media (max-width: 390px) {
  .card-register, .card-login {
    width: 350px;
    height: 350px;
  }
}@media (max-width: 360px) {
  .card-register, .card-login {
    width: 340px;
    height: 347px;
  }
}

.error, .existing-email-error {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #FD8181;
  width: 100%;
  text-align: left;
  margin: 2px 0;
}

.card-register.loading {
  opacity: 1;
}

.loading-indicator {
  width: 50px;
  height: auto;
  animation: spin 2s linear infinite;
}




@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.header-container-white {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-image-white, .header-image {
  max-width: 40%;
  height: auto;
}
.header-image-white {
  margin-bottom: 10px; /* Ви можете змінити значення відступу на будь-яке, яке вам підходить */
}
.header-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #ffffff;
}

/* register.css */
.submit-btn {
  width: 100%;
  height: 40px;
  margin-top: 20px; /* Default margin */
  padding: 10px 20px;
  background: #529293;
  cursor: pointer;
  border: none;
  color: #ffffff;
  font-weight: bold;
  border-radius: 5px;
  transition: background 0.3s ease, margin-top 0.3s ease; /* Smooth transition for color change */
}

.submit-btn.error-margin {
  margin-top: 30px; /* Adjusted margin when error is present */
}

.submit-btn:hover {
  background: #458C8D;
}

.submit-btn:active {
  background: #65ACAD;
}
.existing-email-error  {
  margin-top: 0; /* No margin if existing-email-error is present */
  margin-bottom: -10px;

}
.submit-btn.existing-email-error-margin{
  margin-top: 15px; /* Adjusted margin when error is present */

}
