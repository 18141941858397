.issues-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background: #ffffff; /* White background for contrast */
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  max-width: 900px; /* Restrict width for better focus */
  margin-left: 270px;
}

.issues-content {
  text-align: center;
  width: 100%;
}

.issues-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #24292e;
}

.issues-description {
  font-size: 16px;
  color: #586069;
  line-height: 1.6;
  margin-bottom: 20px;
}

.issues-button {
  padding: 12px 26px;
  background-color: #0366d6;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 14px;
  margin-bottom: 14px;

  &:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 12px rgba(0, 86, 179, 0.3);
  }
}

.issues-toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.issues-toggle-label {
  font-size: 16px;
  margin-right: 15px;
  color: #333;
}

.toggle-switch {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #cccccc;
    transition: background-color 0.4s ease;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: "";
      height: 22px;
      width: 22px;
      left: 4px;
      top: 4px;
      background-color: white;
      transition: transform 0.4s ease;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: #007bff;

    &::before {
      transform: translateX(28px);
    }
  }
}

/* Mobile adjustments */
@media (max-width: 767px) {
  .issues-container {
    padding: 0;
    padding-left: 7px; /* Ensure left padding on mobile */
    margin-left: 0; /* Remove left margin for mobile */
    box-shadow: none; /* Simplify shadow for mobile */
    border-radius: 0; /* Remove border radius for full-width look on mobile */
  }

  .issues-title {
    font-size: 24px; /* Adjusted for smaller screens */
  }

  .issues-description {
    font-size: 14px; /* Slightly smaller for mobile */
  }

  .issues-button {
    width: 100%;
    padding: 10px 0;
    font-size: 14px;
  }

  .issues-toggle-label {
    font-size: 14px;
  }

  .switch {
    width: 50px; /* Slightly smaller for mobile */
    height: 25px;

    .slider::before {
      height: 18px;
      width: 18px;
      transform: translateX(0px);
    }
  }
}

.dark-theme {

  .issues-container {

    background: #111315; /* White background for contrast */

    .issues-title {
      color: #EDEDED;
    }

    .issues-description {
      color: #EDEDED;

    }
    .issues-toggle-label {
    color: #EDEDED;
    }
    }
}