

.confirm-email-card img.logo {
    width: 100px;
    margin-bottom: 1rem;
}


.confirm-email-card p {
    margin-bottom: 2rem;
    font-size: 16px;
    color: #333;
}


.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #529293;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.header-container-white-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

