@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

$Poppins: "Poppins", sans-serif;

// colors
$dark: rgb(29, 29, 29);
$darkD: #111315;

$light: #fff;
$lightD: #EDEDED;

$primary: #499293;
$primaryOne: #11595a;
$primaryHover: #133030B2;

$bg: rgb(244, 244, 255);

// New color variables
$navbarBackground: #fff;
$navbarText: #000;
$navbarHover: #d32f2f;

// spacing
$spacing-md: 16px;
$spacing-lg: 32px;

// border radius
$borderRadius: 12px;
$borderRadiusS: 0 0 12px 12px;

// header height
$headerHeight: 71px;

@mixin breakpoint($point) {
    @if $point == md {
        // 768px
        @media (min-width: 48em) {
            @content;
        }
    }
}

.header {
    background: $navbarBackground;
    width: 100%;
    height: $headerHeight;
    padding: 0 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #0000001A;
    transition: 0.3s ease all;

    @include breakpoint(md) {
        padding: 0 3rem;
    }

    &__content {
        color: $navbarText;
        width: 3000px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 100;

        img {
            width: 141px;
            height: 24px;
        }

        &__nav {
            position: fixed;
            top: $headerHeight;
            right: 0;
            width: 100%;
            background: $navbarBackground;
            backdrop-filter: blur(2px);
            transform: translateY(-150%);
            transition: transform 0.3s ease;
            border-radius: 0 0 8px 8px;


            @include breakpoint(md) {
                position: static;
                transform: none;
                background: none;
                height: auto;
                flex-direction: row;
                justify-content: flex-end;
                width: auto;
            }

            ul {
                list-style: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                margin-left: 15px;

                @include breakpoint(md) {
                    flex-direction: row;
                    margin-right: 1.5rem;
                }

                li {
                    margin: 1rem 0;

                    @include breakpoint(md) {
                        margin: 0 1.5rem;
                    }

                    a {
                        text-decoration: none;
                        color: $navbarText;
                        font-size: 1.2rem;
                        transition: color 0.3s ease;
                        position: relative;

                        &:hover {
                            color: $primaryOne;
                        }

                        &.active {
                            color: $primary;

                            &::after {
                                content: '';
                                display: block;
                                width: 100%;
                                height: 2px;
                                background-color: $primary;
                                position: absolute;
                                bottom: -24px;
                                left: 0;

                                @media (max-width: 767px) {
                                    bottom: auto;
                                    left: -15px;
                                    top: 0;
                                    width: 2px;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }

            button {
                margin-top: 1rem;
                padding: 0.75rem 1.5rem;
                border-radius: $borderRadius;
                background: $navbarHover;
                color: $light;
                border: none;
                font-size: 1rem;
                cursor: pointer;
                transition: background 0.3s ease;

                &:hover {
                    background: lighten($navbarHover, 10%);
                }

                @include breakpoint(md) {
                    margin-top: 0;
                }
            }

            &.isMenu {
                transform: translateX(0);
            }
        }

        &__icons {
            display: flex;
            align-items: center;
        }

        &__toggle {
            display: flex;
            align-items: center;
            font-size: 2rem;
            cursor: pointer;

            @include breakpoint(md) {
                display: none;
            }

            svg {
                color: $navbarText;
            }
        }


        .icon {
            position: relative;
            margin-left: 10px;
            height: 20px;
            width: 20px;
            cursor: pointer;

            // Adjust position and size for medium screens and up
            @include breakpoint(md) {
                right: 30px;
                height: 24px; // Slightly larger icon on medium screens and above
                width: 24px;
            }

            // For smaller screens (less than 768px)
            @media (max-width: 47.9em) {
                right: -50px;
                height: 18px; // Slightly smaller icon on small screens
                width: 18px;
            }

            // For screens exactly 375px wide
            @media (max-width: 375px) {
                right: -35px;
                height: 16px; // Even smaller icon for 375px screens
                width: 16px;
            }
        }


        .logoutLink {
            align-items: center;
            justify-content: center;
            color: $dark;
            text-decoration: none;
            font-size: 1.2rem;
            transition: color 0.3s ease;

            &:hover {
                color: #13303080;

            }

        }

        .username {
            margin-right: 40px;
            font-size: 1rem;
            font-weight: 400;
            color: $navbarText; // This will be your default light theme color
            cursor: pointer;

            &:hover {
                color: $primaryHover;
            }

            // Dark theme styles
            .headerDark & {
                color: $lightD; // Use light color for dark theme

                &:hover {
                    color: $primary;
                }
            }

            // Adjust styles for mobile screens
            @media (max-width: 768px) {
                display: inline-block; // Show username on mobile
                margin-right: 15px; // Adjust margin for spacing in mobile layout
                font-size: 0.9rem; // Slightly smaller font size for mobile
            }
        }
    }
}

        .no-scroll {
    overflow: hidden;
    height: 100%;
}

html.no-scroll,
body.no-scroll {
    overflow: hidden;
    height: 100%;
}

.headerDark {
    background: $darkD;
    color: $light;
    border: 1px solid rgba(237, 237, 237, 0.1);

    .header__content {
        color: $light;
    }
    .header__content__nav {
        background: $darkD;
    }

        ul li a {
            color: $light;
    }

    .header__content__toggle svg,
    .header__content__icons,
    .header__content .logoutLink {
        color: $light;

        &:hover {
            color: $primary;

        }
    }
}
.notificationWrapper {
    top: 4px;
    position: relative;

    .notificationsDropdown {
        position: absolute;
        top: 100%;
        right: 0;
        width: 335px;
        background: $light;
        border: 1px solid #ddd;
        border-radius: $borderRadius;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
        z-index: 3;
        padding: 10px 0 0;
        text-align: center;

        .notificationsHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 13px;
            border-bottom: 1px solid #ddd;

            p {
                font-size: 14px;
                font-weight: 500;
                margin: 0 0 0 15px;
            }

            .moreOptionsButton {
                background: none;
                border: none;
                color: $navbarText;
                font-size: 1.5rem;
                cursor: pointer;
                padding: 0;
                display: flex;
                align-items: center;
            }

            .moreOptionsIcon {
                font-size: 14px;
                width: 24px;
                height: 24px;
                color: #92929D;
                margin-right: 8px;
            }
        }

        .notificationsList {
            list-style: none;
            margin: 0;
            padding: 0;
            max-height: 225px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #458C8D;
                border-radius: 10px;
                border: 2px solid #f1f1f1;
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #65ACAD;
            }

            li {
                position: relative;
                padding: 5px 10px;
                cursor: pointer;
                display: flex;
                align-items: center;

                &.readNotification {
                    font-weight: normal;
                    color: grey;

                    .notificationCircle {
                        display: none;
                    }
                }

                &:last-child {
                    border-bottom: none;
                }

                .notificationCircle {
                    margin-right: 8px;
                    width: 16px;
                    height: 10px;
                    background-color: transparent;
                    border: 1px solid $primary;
                    border-radius: 50%;
                }
            }
        }


        .clearAllButton {
            background: lighten($light, 5%);
            border: 1px solid lighten($primary, 40%);
            color: $primary;
            cursor: pointer;
            font-size: 0.75rem;
            font-weight: 600;
            padding: 8px 0;
            margin-top: 10px;
            text-align: center;
            width: 100%;
            border-radius: $borderRadiusS;
            height: 40px;
            position: sticky;
            bottom: 0;

            &:hover {
                color: darken($primary, 10%);
                background: lighten($light, 10%);
                text-decoration: underline;
            }
        }
    }

    @media (max-width: 768px) {
        .notificationsDropdown {
            width: 280px;

            .notificationsHeader p {
                font-size: 12px; // Reduce font size for smaller screens
            }

            .notificationsList li {
                padding: 5px 8px;

                .notificationCircle {
                    width: 14px;
                    height: 14px; // Smaller circle for mobile
                }
            }

            .clearAllButton {
                font-size: 0.7rem; // Adjust button font size for mobile
                height: 35px; // Adjust button height
            }
        }
    }

    @media (max-width: 480px) {
        .notificationsDropdown {
            right: -45px;
            width: 290px; // Further reduce the width for smaller screens

            .notificationsHeader p {
                font-size: 11px;
            }

            .moreOptionsIcon {
                width: 20px;
                height: 20px; // Smaller icon size for smaller screens
            }

            .notificationsList li {
                padding: 4px 6px;

                .notificationCircle {
                    width: 14px;
                    height: 7px;
                }
            }

            .clearAllButton {
                font-size: 0.65rem;
                height: 30px;
            }
        }
    }

    .headerDark & {
        .notificationsDropdown {
            color: $darkD;

            .notificationsList li {
                border-bottom: 1px solid #444;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}
