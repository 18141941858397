
.chat-container {
  background-image: url("../icon/img_27.png"); /* Додає фонове зображення */
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.virtual-scroll {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
}

.chat-container-virtual-scroll {
  width: 100%;
  height: 100vh; /* Базове значення - висота дорівнює висоті екрану */
  overflow-y: auto;
  position: relative;
  transition: height 0.3s ease-in-out; /* Додаємо плавний перехід для зміни висоти */
}

/* Адаптація висоти для середніх екранів */
@media (max-height: 1600px) {
  .chat-container-virtual-scroll {
    height: 91vh;
  }
}

@media (max-height: 1200px) {
  .chat-container-virtual-scroll {
    height: 89vh;
  }
}

@media (max-height: 1100px) {
  .chat-container-virtual-scroll {
    height: 88vh;
  }
}


@media (max-height: 970px) {
  .chat-container-virtual-scroll {
    height: 86.4vh;
  }
}
@media (max-height: 880px) {
  .chat-container-virtual-scroll {
    height: 85vh;
  }
}

/* Адаптація для невеликих екранів */
@media (max-height: 800px) {
  .chat-container-virtual-scroll {
    height: 83vh;
  }
}

/* Адаптація для дуже маленьких екранів */
@media (max-height: 700px) {
  .chat-container-virtual-scroll {
    height: 81vh;
  }
}
@media (max-height: 600px) {
  .chat-container-virtual-scroll {
    height: 78vh;
  }
}

.unread-message-label {
  background-color: #e0f7fa; /* Світло-блакитний фон */
  color: #006064; /* Темний текст для контрасту */
  text-align: center;
  padding: 8px 12px;
  font-weight: bold;
  border-radius: 8px;
  margin: 10px auto; /* Center it horizontally */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-width: 200px;
  //display: inline-block; /* Ensure the label doesn't stretch too much */
  font-size: 14px;
  animation: fadeIn 0.5s ease-in-out; /* Smooth fade-in effect */
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-container-virtual-scroll .chat-body a {
  cursor: pointer;
}

.chat-container-virtual-scroll::-webkit-scrollbar {
  width: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Style the scrollbar thumb */
.chat-container-virtual-scroll::-webkit-scrollbar-thumb {
  background-color: #529293;
  border-radius: 10px;
  transition: background-color 0.2s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.cart-message-wrapper {

  padding: 10px;
  border-radius: 8px;
  margin: 10px 0;
  position: relative;
  overflow: hidden;





  .chat-message {
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 588px;
    width: 100%;

    &.deleted {
      background-color: #ffc2bc; /* Background closer to red */

    }

    &.modified {
      background-color: #fbebba; /* Closer to yellow */
    }



    .chat-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .chat-username {
        font-weight: bold;
        color: #49A8FF;
      ;
      }

      .chat-group {
        font-size: 12px;
        color: #00000080;

      }
    }

    .chat-body {
      font-size: 14px;
      line-height: 1.5;
      word-wrap: break-word;
      white-space: pre-wrap;

      a {
        color: #007bff;
        text-decoration: underline;
        &:hover {
          color: #0056b3;
        }
      }
    }

    .chat-time {
      font-size: 12px;
      color: #999;
      text-align: right;
      margin-top: 10px;
    }
  }

  .chat-message.deleted .chat-time, .chat-group {
    color: #111315; /* Темнй червоний колір */
  }

  //.chat-message.deleted .chat-username {
  //  color: #111315; /* Темнй червоний колір */
  //}

  .chat-message.modified .chat-time, .chat-group{
    color: #111315; /* Темний жовтий колір */
  }
}
.dark-theme {

  .chat-message {
    background-color: #111315;
    color: #e0e0e0;


    &.deleted {
      background-color: #bf6d6d; /* Dark red */
      color: #111315;
    }

    &.modified {
      background-color: #aa9a54; /* Dark yellow */
      color: #111315  !important;
    }


    &::before {
      border-bottom-color: #111315;
    }

    .chat-header .chat-username {
      color: #49A8FF;
    }

    .chat-header .chat-group {
      color: #e5f2f2;
    }

    .chat-body a {
      color: #4da6ff;

      &:hover {
        color: #3399ff;
      }
    }

    .chat-time {
      color: #cccccc;
    }
    &.deleted .chat-time {
      color: #111315;
    }



  }
}





