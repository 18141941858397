.sidebar-layout {
  display: flex;
  height: 93vh;
  width: 100vw;
  background: #FFFFFF;
  padding: 20px;
  position: relative;
}

.dark-theme .sidebar-layout {
  background: #111315;
}

.sidebar {
  position: absolute;
  width: 270px;
  padding: 20px;
  background-color: #FFFFFF;
  border-right: 1px solid #ddd;
  left: 0;
  top: 0;
  height: 100%;
  border-right: 1px solid #ccc;
  transition: transform 0.3s ease;
}

.dark-theme .sidebar {
  background-color: #111315;
  border-right: 1px solid rgba(237, 237, 237, 0.1);
}

.menu-item {
  display: inline-flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  color: #333;
  font-size: 16px;
  text-decoration: none;
  margin-left: 9px;
  position: relative;

  &:hover {
    color: #133030B2;
  }

  &.active {
    color: #529293;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: #529293;
      position: absolute;
      bottom: -4px;
      left: 0;
    }
  }
}

.dark-theme .menu-item {
  color: #EDEDED;

  &.active {
    color: #EDEDED;

    &::after {
      background-color: #529293;
    }
  }
}

.menu-item:hover {
  text-decoration: none;
}

.menu-item:not(:last-child) {
  margin-bottom: 15px;
}

.sidebar-toggle {
  display: none;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 1000;
}

.arrow {
  display: inline-block;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(90deg);
}

@media (max-width: 767px) {
  .sidebar {
    width: 180px;
    transform: translateX(-100%); /* Hide sidebar by default */
  }

  .sidebar.open {
    transform: translateX(0); /* Show sidebar when open */
    z-index: 2;

  }

  .sidebar-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 24px;
    color: #000000;
    background: none;
    border: none;
    position: fixed;
    top: 50%;
    left: 15px;
    z-index: 1000;
    transform: translateY(-50%);
    transition: transform 0.3s ease, color 0.3s ease;
  }

  .sidebar-toggle:hover {
    color: #555555; /* Slight color change on hover */
  }

  .dark-theme .sidebar-toggle {
    color: #FFFFFF; /* Change arrow color to white in dark theme */
  }

  .arrow {
    display: inline-block;
    transition: transform 0.3s ease;
  }

  .arrow.open {
    transform: rotate(90deg);
  }

  .content {
    overflow-x: hidden; // При закритому меню прокрутка не потрібна
  }

  .content-overflow {
    overflow-x: auto; // Додає горизонтальне прокручування, коли меню відкрите
  }


  .sidebar-layout.sidebar-open .content {
    padding: 0;
  }
}
