/* confirm.css */
.confirm-email-card {
    background-color: #fff;
    border-radius: 10px;
    width: 24rem;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.316);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 190px;
}

.header-container-white {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-image-white, .header-image {
    max-width: 40%;
    height: auto;
}
.header-image-white {
    margin-bottom: 10px; /* Ви можете змінити значення відступу на будь-яке, яке вам підходить */
}
.confirm-email-card img.logo {
    width: 100px;
    margin-bottom: 1rem;
}

.confirm-email-card h2 {
    margin-bottom: 0.8rem;
}

.confirm-email-card p {
    margin-bottom: 2rem;
    font-size: 16px;
    color: #333;
}

