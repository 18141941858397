.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 40px; /* Increased padding for a larger popup */
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  width: 400px; /* Fixed width for larger screens */

  @media(max-width: 600px) {
    width: 90%; /* Adjust for smaller screens */
  }
}

.popup-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #529293;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 320px; /* Fixed width for larger screens */

}

.popup-button:hover {
  background-color: #458C8D;
}
