.suggestions-list {
    position: absolute;
    top: 100%;
    left: 9px;
    width: calc(100% - 18px); /* Adjusted for padding */
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ddd;
    background: #fff;
    z-index: 3;
    border-radius: 12px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    padding: 10px 0;
    margin-top: 5px;
    transition: box-shadow 0.2s ease-in-out;
    padding-left: 9px;
    padding-right: 9px;
}

.suggestions-list li {
    padding: 12px 18px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 8px;
    transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out;
    font-size: 12px; /* Smaller font size */
}

.suggestions-list li:hover {
    background-color: #f5f5f5;
    transform: scale(1.02);
}

/* Custom Scrollbar Styling */
.suggestions-list::-webkit-scrollbar {
    width: 10px;
}

.suggestions-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.suggestions-list::-webkit-scrollbar-thumb {
    background-color: #458C8D;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.suggestions-list::-webkit-scrollbar-thumb:hover {
    background-color: #65ACAD;
}
.filter-input-section {
    width: 100%;
    position: relative; /* Додаємо відносне позиціонування для контейнера */
    margin-bottom: 1px;
}

.input-with-button {
    display: flex;
    align-items: center;
    width: 100%;
}

.filter-input {
    width: 100%;
    padding-right: 40px;
    box-sizing: border-box;
}
